const Tdata = [
  {
    cover: "./images/industry/electronics.jpg",
    para: "Electronics",
    desc: "3k orders this week",
  },
  {
    cover: "./images/industry/aerospace.jpg",
    para: "Aerospace",
    desc: "4k orders this week",
  },
  {
    cover: "./images/industry/medical.jpg",
    para: "Medical",
    desc: "6k orders this week",
  },
  {
    cover: "./images/industry/audio.jpg",
    para: "Audio",
    desc: "4k orders this week",
  },
  {
    cover: "./images/industry/textile.jpg",
    para: "Textile",
    desc: "6k orders this week",
  },
  {
    cover: "./images/industry/vending.jpg",
    para: "Vending Machines",
    desc: "6k orders this week",
  },
]

export default Tdata
