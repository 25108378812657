import WireHarness from "./WireHarness"
// import Transformers from "./Transformers"

const Products = () => {
    return (
      <>
        <WireHarness />
        {/* <Transformers /> */}
      </> 
    )
  }
  
  export default Products
  